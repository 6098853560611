import AppRouter from "./routes/AppRouter";
import "./Language"; // Import i18n configuration

function App() {
  return (
    <>
      <AppRouter/>   
    </>

  );
}

export default App;
