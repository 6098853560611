const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  
  endPoints: {
    authLogin: 'auth/login',
    authLogout: 'auth/logOut',
    authRegister: 'auth/register',
    sendPasswordResetEmail : 'auth/reset-password-link',
    resetUserPassword :  'auth/reset-password',
    myProfile : 'auth/profile',
    updateProfile : 'auth/updateProfile',
    myOrder: 'order/myOrder',
    createOrder : 'order/createOrder',
    uploadDoc: 'order/uploadDoc',
    getSingleOrder: 'order/getSingleOrder',
    createPayment: 'payment/createPayment',
    createPlan: 'plan/createPlan',
    getPlans: 'plan/getPlans',
    getSinglePlan: 'plan/getSinglePlan',
    updatePlan: 'plan/updatePlan',
    deleteSinglePlan: 'plan/deleteSinglePlan',
    deletePlans: 'plan/deletePlans',
    createService: 'service/createService',
    getServices: 'service/getServices',
    getCountries: 'countries/getCountries',
    getLanguages: 'languages/getLanguages',
    getTargetLanguages: 'targetLanguage/get',
    deleteTargetLanguageById: 'targetLanguage/delete',
    deleteAllTargetLanguages: 'targetLanguage/delete',
    getTopics: 'topic/getTopics',
    getAllNotifications: 'notifications',
    markAsRead: 'notifications/mark-as-read',
    markAllAsRead: 'notifications/mark-all-as-read',
  },
};

export { config };