export const formatDate = (date) => {
    const now = new Date();
    const pastDate = new Date(date);
    const seconds = Math.floor((now - pastDate) / 1000);

    const intervals = [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 }
    ];

    for (const interval of intervals) {
        const intervalSeconds = interval.seconds;
        const count = Math.floor(seconds / intervalSeconds);
        if (count > 0) {
            return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
        }
    }

    return 'just now'; // fallback
};
